import React from 'react';
import { motion } from "framer-motion";

//Imagenes
import tuerca from '../img/tuerca.png';

const Home = () => {

    const pAnimate = {opacity: [0, 0 ,0, 0, 0 , 0, 100]};
    const pTransition = {duration: 2};
    const pInitial = {opacity: 0};



    return (
        <div className='home__div--container---total'>
            <motion.div
            transition={{
                duration: 0.2
            }}

            initial={{
                opacity: 0
            }}

            animate={{
                opacity: 100
            }}
            className='home__div--container'>
                <div className='home__div--container--animacion-construccion'>
                    <div className='home__div--soporte-grua'></div>
                    <div className='home__div--brazo-grua'></div>
                    <motion.div
                        className='home__div--cable home__div--cable1'

                        transition={{
                            duration: 2
                        }}

                        animate={{
                            height: "200px"
                        }}
                    ></motion.div>
                    <motion.div
                        className='home__div--cable home__div--cable2'

                        transition={{
                            duration: 2
                        }}

                        animate={{
                            height: "200px"
                        }}
                    ></motion.div>
                    <motion.div
                        className='home__div--letrero'
                        transition={{
                            duration: 2
                        }}

                        animate={{
                            y: "140px"
                        }}
                    >
                        <motion.div transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__div--container-frase-en'>
                            <p className='home__p--frase-en'>EN</p>
                        </motion.div>
                        <div                     className='home__div--container-frase-container'
                        >
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate} className='home__p--frase-1'>C</motion.p>
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-2'>O</motion.p>
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-1'>N</motion.p>
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-2'>S</motion.p>
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-1'>T</motion.p>
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-2'>R</motion.p>
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-1'>U</motion.p>
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-2'>C</motion.p>
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-1'>C</motion.p>
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-2'>I</motion.p>
                            <motion.img

                                transition={{
                                    duration: 2
                                }}

                                initial={{
                                    x: "-140px",
                                    rotate: 0,
                                }}

                                animate={{
                                    x: "0px",
                                    rotate: 360,
                                }}
                                src={tuerca}
                                alt='tuerca'
                            />
                            <motion.p transition={pTransition} initial={pInitial} animate={pAnimate}  className='home__p--frase-1'>N</motion.p>
                        </div>
                    </motion.div>
                </div>

                <motion.div
                    transition={{
                        duration: 4
                    }}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 100]
                    }}
                    className='home__div--container-pronto'>
                    <p className='home__div--pronto'>PRONTO</p>
                    <div className='home__div--adorno'></div>
                </motion.div>
            </motion.div>
        </div>
    )
}

export default Home;