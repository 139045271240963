//Estructura ejemplo
// {
//     frase: "Los gatos blancos",
//     traduccion: ["the white cats", "the whiter cats"],
//     vocabulario: ["Blanco - White", "Gato - Cat"]
// },



let articulos = [
    {
        frase: "El radio",
        traduccion: ["the radio"],
        vocabulario: ["Radio - Radio"]
    },
    {
        frase: "Las manzanas",
        traduccion: ["the apples"],
        vocabulario: ["Manzanas - Apples"]
    },
    {
        frase: "Una puerta",
        traduccion: ["a door"],
        vocabulario: ["Puerta - Door"]
    },
    {
        frase: "Unos niños",
        traduccion: ["some children"],
        vocabulario: [ "Niños - Children"]
    },
    {
        frase: "Los gatos",
        traduccion: ["the cats"],
        vocabulario: [ "Gatos - Cats"]
    },
    {
        frase: "El apartamento",
        traduccion: ["the apartment"],
        vocabulario: [ "Apartamento - apartment"]
    },
    {
        frase: "Una casa",
        traduccion: ["a house"],
        vocabulario: [ "Casa - House"]
    },
    {
        frase: "Algunas ciudades",
        traduccion: ["some cities"],
        vocabulario: [ "Ciudad(es) - City (Cities)"]
    },
    {
        frase: "La secretaria",
        traduccion: ["the secretary"],
        vocabulario: [ "Secretaria - Secretary"]
    },
    {
        frase: "Unas Ventanas",
        traduccion: ["some windows"],
        vocabulario: [ "Ventanas - Windows"]
    },
    {
        frase: "Un cantante",
        traduccion: ["a singer"],
        vocabulario: [ "Cantante - Singer"]
    },
    {
        frase: "Un avión",
        traduccion: ["an airplane"],
        vocabulario: [ "Avión - Airplane"]
    },
    {
        frase: "Unas sillas",
        traduccion: ["some chairs"],
        vocabulario: [ "Silla - Chair"]
    },
    {
        frase: "Los cantantes",
        traduccion: ["the singers"],
        vocabulario: [ "Cantante(s) - Singer(s)"]
    },
    {
        frase: "Las secretarias",
        traduccion: ["the secretaries"],
        vocabulario: [ "Secretaria(s) - Secretary (ries)"]
    }
];

export {articulos};