import { BrowserRouter, Route, Routes } from 'react-router-dom';


//Componentes
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Articulos from './pages/ejercicios/Articulos';


function App() {
  return (
    <BrowserRouter>
      <NavBar/>
      <Routes>
        <Route path='/' element={<Home/>}/>

        {/* ejercicios */}
        <Route path='/ejercicios/*'>
          <Route path='articulos' element={<Articulos/>}/>
        </Route>


      </Routes>
    </BrowserRouter>
  );
}

export default App;
