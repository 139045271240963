import React from 'react';

//Imagenes
import logoYoutube from '../img/youtube.png';

const BotonYoutube = (props) => {
    const { nombre, link } = props;

    const irAlLink = () => {
        window.open(link, "_blank");
    }


    return (
        <div className='boton-youtube__div--container' onClick={() => irAlLink()}>
            <button className='boton-youtube__button'>
                {nombre}
                <img className='boton-youtube__img--logo-youtube' src={logoYoutube} alt='logo-youtube'/>
            </button>
        </div>
    )
}

export default BotonYoutube;