
//Funciones para el ejercicio de traduccion.

//Funcion para separar palabras en un array
const separarPalabras = (oracion) => {
    let palabraSeparada = oracion.split(" ");
    return palabraSeparada;
}


// ["Hola", "como", "estas"];
//
const devolverCoincidencias = (input, traduccion) => {
    let inputSeparado = separarPalabras(input);



    let aciertosOracion = separarPalabras(traduccion[0]).map(palabra => {
        return "_______";
    });


    for (let i = 0; i < traduccion.length; i++) {
        let traduccionSeparada = separarPalabras(traduccion[i]);

        for (let j = 0; j < inputSeparado.length; j++) {
            // console.log("Validando aciertos oracion", aciertosOracion);
            if(inputSeparado[j].toLowerCase() === traduccionSeparada[j].toLowerCase()){
                aciertosOracion[j] = inputSeparado[j];
            }
        }
    }

    console.log("acierto oracion final", aciertosOracion);
    return aciertosOracion.join(" ");
}


export {devolverCoincidencias};