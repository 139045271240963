import React from 'react'
import PlantillaEjercicio from '../../components/PlantillaEjercicio'
import { articulos } from '../../lib/ejercicios';

let links = ["", "", ""];

const Articulos = () => {
    return (
        <div>
            <PlantillaEjercicio tema={articulos} nombre="Articulos" links={links}/>
        </div>
    )
}

export default Articulos