import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import ReactHowler from 'react-howler';

//Imagenes
import estrella from '../img/estrella.png';
import { devolverCoincidencias } from '../lib/funciones';
import BotonYoutube from './BotonYoutube';

//Sonidos
import acierto from '../sounds/bright-notifications-151766.mp3';

const PlantillaEjercicio = (props) => {
    const [ inputEjercicio, setInputEjercicio] = useState("");

    const { tema, nombre, links } = props;

    const [ consecutivo, setConsecutivo ] = useState(0);
    const [ ejercicioActual, setEjercicioActual ] = useState(tema[consecutivo]);
    const [ hecho, setHecho ] = useState(false);
    const [ siguiente, setSiguiente ] = useState(true);
    const [ opcionesRecomendadas, setOpcionesRecomendadas ] = useState(false);
    const [ estilosVocabulario, setEstilosVocabulario ] = useState({height: "45px", overflow: "hidden"});
    const [ resolucionTraduccion, setResolucionTraduccion] = useState("");
    const [ revisarTraduccion, setRevisarTraduccion ] = useState("");
    const [ activarSonido, setActivarSonido ] = useState(false);

    useEffect(() => {
        if(tema.length === consecutivo + 1){
            setSiguiente(false);
            setOpcionesRecomendadas(true);
        };

        if(ejercicioActual.traduccion.includes(inputEjercicio.toLowerCase())){
            setTimeout(() => {
                setHecho(true);
                setTimeout(() => {
                    setActivarSonido(false);
                }, 2000);
                setActivarSonido(true);
            }, 1000);
        };


        for (let i = 0; i < ejercicioActual.traduccion.length; i++) {
            if(ejercicioActual.traduccion[i].split(" ").length === inputEjercicio.split(" ").length && ejercicioActual.traduccion[i] !== inputEjercicio){
                setTimeout(() => {
                    setRevisarTraduccion("Revisar o completar traducción");
                }, 3000);
            }else if(ejercicioActual.traduccion[i].split(" ").length !== inputEjercicio.split(" ").length){
                setRevisarTraduccion("");
            }
        }

        // console.log("consecutivo",consecutivo);
    }, [inputEjercicio, siguiente,  hecho]);



    //Funcion para ir al siguiente ejercicio
    const siguienteEjercicio = () => {
        setConsecutivo(consecutivo + 1);


        setTimeout(() => {
            setEjercicioActual(tema[consecutivo + 1]);
            setInputEjercicio("");
            setHecho(false);
            setActivarSonido(false);
        }, 1000);
    }


    //Estilos funcion vocabulario
    const mouseDentro = () => {
        setEstilosVocabulario({height: "200px", overflow: "auto"});
    }

    const mouseFuera = () => {
        setEstilosVocabulario({height: "45px", overflow: "hidden"});
        document.querySelector(".plantilla__div--container-vocabulario").scrollTo({ top: 0 });
    }

    useEffect(() => {
        setResolucionTraduccion(devolverCoincidencias(inputEjercicio, tema[consecutivo].traduccion))
    }, [inputEjercicio]);


    return (
        <div className='plantilla-ejercicio__div--container'>
            <p className='plantilla-ejercicio__p--leccion'>lección</p>
            <h2 className='plantilla-ejercicio__h2--nombre'>{nombre}</h2>
            <p className='plantilla-ejercicio__p--cantidad-lecciones'>{`${consecutivo + 1} / ${tema.length}`}</p>
            {hecho ? (
                <div>
                <ReactHowler src={acierto} playing={activarSonido}/>
                    <motion.div
                        className='plantilla-ejercicio__div--container-estrellas'

                        transition={{
                            duration: 2
                        }}

                        animate={{
                            opacity: [0, 1]
                        }}
                    >
                        <motion.img
                            transition={{
                                duration: 2
                            }}

                            animate={{
                                rotate: [0, 180, 0]
                            }}


                            className='plantilla-ejercicio__img--estrella-1' src={estrella} alt='estrella'/>
                        <motion.img
                            transition={{
                                duration: 2
                            }}

                            animate={{
                                x: [0, 0, -70],
                                y: [0, 0, -50]
                            }}


                        className='plantilla-ejercicio__img--estrella-2' src={estrella} alt='estrella'/>
                        <motion.img
                            transition={{
                                duration: 2
                            }}

                            animate={{
                                x: [0, 0, 70],
                                y: [0, 0, -42]
                            }}

                        className='plantilla-ejercicio__img--estrella-3' src={estrella} alt='estrella'/>
                    </motion.div>
                    <motion.p
                            transition={{
                                duration: 2
                            }}

                            animate={{
                                scale: [0, 1]
                            }}

                        className='plantilla-ejercicio__p--traduccion-correcta'>Bien hecho</motion.p>
                        <div className='plantilla-ejercicio__div--container-resultado'>
                            <p className='plantilla-ejercicio__p--frase'>{ejercicioActual.frase}</p>
                            <p className='plantilla-ejercicio__p--traduccion-traduccion'>{inputEjercicio}</p>
                        </div>
                    {siguiente ? (
                        <div className='plantilla-ejercicio__div--siguiente-container'>
                            <button className='plantilla-ejercicio__button--siguiente' onClick={() => siguienteEjercicio()}>Siguiente</button>
                        </div>):("")}
                    {opcionesRecomendadas ? (
                        <div>
                            <p className='plantilla-ejercicio__p--lecciones-recomendadas'>Lecciones recomendadas</p>
                            <div className='plantilla-ejercicio__div--container-lecciones-recomendadas'>
                                <BotonYoutube nombre="Verbo To Be" link="https://www.youtube.com/watch?v=yK6UAi44I_I"/>
                                <BotonYoutube nombre="Adjetivos" link="https://www.youtube.com/watch?v=yK6UAi44I_I"/>
                                <BotonYoutube nombre="Presente perfecto" link="https://www.youtube.com/watch?v=yK6UAi44I_I"/>
                            </div>
                        </div>
                    ):("")}
                </div>
            ):(
                <motion.div
                    transition={{
                    duration: 2
                }}

                animate={{
                    opacity: [0, 1]
                }}
                >
                    <div className='plantilla__div--container-ejercicio'>
                        <p className='plantilla__p--traduce'>Traduce</p>
                        <p className='plantilla__p--frase-ejercicio'>{ejercicioActual.frase}</p>
                        <p className='plantilla__p--aciertos'>Aciertos</p>
                        <p className='plantilla__p--resolucion-traduccion'>{resolucionTraduccion}</p>
                        <input
                            className='plantilla__input--respuesta'
                            type='text'
                            value={inputEjercicio}
                            onChange={(e) => setInputEjercicio(e.target.value)}
                        />
                        <p className='plantilla__input--revisar-traduccion'>{revisarTraduccion}</p>
                    </div>
                    <div className='plantilla__div--container-vocabulario' onMouseEnter={() => mouseDentro()} onMouseLeave={() => mouseFuera()} style={estilosVocabulario}>
                        <p>Vocabulario</p>
                        <ul>
                            {tema[consecutivo].vocabulario.map(palabra => {
                                return <li>{palabra}</li>
                            })}
                        </ul>
                    </div>
                </motion.div>
            )}

        </div>
    )
}

export default PlantillaEjercicio